import AirportsAPI from "../data/api/airports-api-store";
export default class AirportService {

    static async searchAirports(body) {
        try {
            const response = AirportsAPI.search(body);
            return response;
        } catch (error) {
            console.error(`Error searching airports`);
            throw error;
        }
    }
    
}
