import { ref, onMounted } from 'vue';
import AirportService from '../services/airports-service';
import { useSearchHistoryStore } from '../stores/search-history-store';
import Fuse from 'fuse.js'

export const useSearch = () => {
    const airports = ref([]);
    const historyDeparture = ref([])
    const historyArrival = ref([])

    const searchHistoryStore = useSearchHistoryStore()

    const getAirportsByIata = (iataArray) => {
        if (!Array.isArray(iataArray) || iataArray.length === 0) return [];

        // Create a Set to store unique airports by IATA code
        const uniqueAirports = new Set();

        // Filter airports by matching IATA codes
        iataArray.forEach(iataCode => {
            const matchingAirports = airports.value.filter(airport => airport.iata.toLowerCase() === iataCode.toLowerCase());
            matchingAirports.forEach(airport => uniqueAirports.add(airport)); // Add matching airports to the Set
        });

        // Return an array of unique airports
        return Array.from(uniqueAirports);
    };

    // Fetch airports
    const getAirports = async () => {

        try {
            airports.value = await AirportService.searchAirports({ text: '' });
        } catch (error) {
            console.error('Error fetching airports', error);
        }

        airports.value = assignRank(airports.value)



        return airports.value

    };

    const localSearchAirport = (keyword, airports, isSearchingHistory, isDeparting) => {

        if (!keyword) return []

        const regex = /^([A-Za-z\s\-']+)\s\(([A-Z]{3})\)$/;
        const match = keyword.match(regex);

        // search only with iata when available
        if (match) {
            keyword = match[2].trim()
        }

        // search only exact match when iata is available
        const fuse = new Fuse(airports, {
            keys: match ? ['iata'] : ['iata', 'city', 'name', 'country', 'region_name'],
            threshold: match ? 0.0 : 0.1,
            minMatchCharLength: match ? 3 : 2,
            ignoreLocation: true
        })

        const fuesRes = fuse.search(keyword)

        const res = fuesRes.map(res => res.item);

        res.sort((a, b) => b.rank - a.rank);

        if (isSearchingHistory) {
            return res
        }

        if (isDeparting) {
            return res.filter(item1 => !historyDeparture.value.some(item2 => item1.iata === item2.iata));

        } else {
            return res.filter(item1 => !historyArrival.value.some(item2 => item1.iata === item2.iata));

        }

    };


    // Format airport as 'City (IATA)'
    const formatAirport = (input) => {
        const matchingAirport = localSearch(input);

        if (matchingAirport[0]) {
            return `${matchingAirport[0].city} (${matchingAirport[0].iata})`;
        } else {
            return input; // Return the original input if no match is found
        }
    };

    const assignRank = (airports) => {
        return airports.map(airport => {
            let rank;

            // Assign rank based on category
            switch (airport.category) {
                case "large":
                    rank = 3;
                    break;
                case "medium":
                    rank = 2;
                    break;
                case "small":
                    rank = 1;
                    break;
                default:
                    rank = 0; // All other categories
                    break;
            }

            // Add the rank field to the airport object
            return { ...airport, rank };
        });
    }

    onMounted(async () => {

        await getAirports();

        historyDeparture.value = getAirportsByIata(searchHistoryStore.getDepartureHistory)
        historyArrival.value = getAirportsByIata(searchHistoryStore.getArrivalHistory)

    });


    return {
        airports,
        getAirports,
        localSearchAirport,
        formatAirport,
        getAirportsByIata,
        historyDeparture,
        historyArrival
    };
};
