import UserSessionsAPI from "../data/api/user-session-api-store.js";

export default class UserSessionService {

  // Method to redirect user to login
  static async login(redirectUrl = '') {
    try {
      const params = redirectUrl ? redirectUrl : '';

      const response = await UserSessionsAPI.login(params);
      return response; // Return the API response if needed
    } catch (error) {
      console.error('Error redirecting user to login - ', error);
      throw error;
    }
  }

  static async logout() {
    try {
      const response = await UserSessionsAPI.logout();
      return response; // Return the API response if needed
    } catch (error) {
      console.error('Error redirecting user to logout - ', error);
      throw error;
    }
  }

  static async create(sessionData) {
    try {
      const response = await SessionsAPI.create(sessionData);
      return response;
    } catch (error) {
      console.error('Error creating session - ', error);
      throw error;
    }
  }

}