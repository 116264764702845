import { reactive, ref, getCurrentInstance, inject, onMounted, computed, watch, nextTick } from 'vue';
import { Dropdown as VDropdown, options } from 'floating-vue';
import 'floating-vue/dist/style.css'
import flatpickr from "flatpickr";
import FloatingVue from 'floating-vue'
import { useAnalytics } from '../../../utilities/use-analytics';
import { useSearch } from '../../../utilities/use-search';
import { useSearchHistoryStore } from '../../../stores/search-history-store';
import { onClickOutside } from '@vueuse/core'

export default {
    props: {
        modelValue: Object,
        type: {
            type: String,
            default: 'search',
            enum: ['search', 'verto']
        }
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        const { airports, historyDeparture, historyArrival, localSearchAirport } = useSearch();

        const trip = ref(props.modelValue);

        const { trackEvent } = useAnalytics()
        const instance = getCurrentInstance();
        const app = instance.appContext.app;

        const languageData = ref(inject('$languageData').value.home)

        const travellersTriggerBtn = ref()

        app.component('VDropdown', VDropdown);
        app.use(FloatingVue)

        const tripTypes = [
            {
                value: 'round',
                label: languageData.value.roundTrip
            },
            {
                value: 'one-way',
                label: languageData.value.oneWay
            }
        ]

        const tripOption = ref(tripTypes[0].label)

        const setTripType = (option) => {
            tripOption.value = option.label
            trip.value.tripType = option.value
            trackEvent('Set_TripType')
        }


        const addExtraPadding = () => {
            return trip.value.hasArrivalAirportError || trip.value.hasDepartureAirportError || trip.value.hasDepartureDateError || (isRound.value && trip.value.hasArrivalDateError)
        }


        const isRound = computed(() => {
            return trip.value.tripType === 'round'
        })


        const selectArrival = ref()
        const departureAirports = ref([])
        const arrivalAirports = ref([])
        const searchKeywordDeparture = ref()
        const searchKeywordArrival = ref()
        const departureDropdown = ref()
        const arrivalDropdown = ref()
        const searchHistoryStore = useSearchHistoryStore()



        const departurePicker = ref()
        const arrivalPicker = ref()


        const createSession = async () => {
            trackEvent('Click_SearchButton')

            trip.value.hasArrivalAirportError = checkError('arrival')
            trip.value.hasDepartureAirportError = checkError('depart')
            trip.value.hasDepartureDateError = checkError('departDate')

            if (isRound.value) {
                trip.value.hasArrivalDateError = checkError('arrivalDate')

            }

            if (isRound.value && trip.value.hasArrivalDateError) {
                return
            }

            if (trip.value.hasArrivalAirportError || trip.value.hasDepartureAirportError || trip.value.hasDepartureDateError) {
                return
            }

            const parmas = {
                departure: searchKeywordDeparture.value,
                arrival: searchKeywordArrival.value,
                departureDate: trip.value.departureDate,
                arrivalDate: trip.value.arrivalDate,
                adults: trip.value.travellers.adult,
                children: trip.value.travellers.children,
                ...(props.type === 'verto' && { searchType: 'verto' }),
            }

            if (isRound.value === false) {
                delete parmas.arrivalDate
            }

            appendToUrl(parmas)

            const currentUrl = window.location.href;

            const url = new URL(currentUrl);

            url.pathname = '/search';

            window.open(url.toString(), '_self');

            searchHistoryStore.addSearchTerm(searchKeywordDeparture.value, 1)
            searchHistoryStore.addSearchTerm(searchKeywordArrival.value, 0)
        }


        const setLocation = async (departure, arrival, hide) => {
            if (departure) {
                searchKeywordDeparture.value = departure.city + ' (' + departure.iata + ')'
                toggleLocationDropdown(1, false)

                selectArrival.value.focus()
                trackEvent('Set_DepartureAirport')
            }

            if (arrival) {
                searchKeywordArrival.value = arrival.city + ' (' + arrival.iata + ')'
                toggleLocationDropdown(0, false)
                trackEvent('Set_ArrivalAirport')
            }

            if (hide) {
                hide()
            }

        }

        const switchLocation = () => {
            if (searchKeywordArrival.value || searchKeywordDeparture.value) {
                let temp = ''
                temp = searchKeywordArrival.value
                searchKeywordArrival.value = searchKeywordDeparture.value
                searchKeywordDeparture.value = temp
            }

        }

        const moveToDropdown = (isDeparting) => {

            if (isDeparting) {
                departureDropdown.value.focus()
            } else {
                arrivalDropdown.value.focus()
            }

        }

        const selectFirst = (isDeparting) => {

            if (isDeparting && searchKeywordDeparture.value) {

                if (filteredHistoryDeparture.value[0]) {
                    setLocation(filteredHistoryDeparture.value[0])
                } else {
                    setLocation(departureAirports.value[0])
                }

                trackEvent('Set_DepartureAirport')
            }

            if (!isDeparting && searchKeywordArrival.value) {

                if (filteredHistoryArrival.value[0]) {
                    setLocation(null, filteredHistoryArrival.value[0])
                } else {
                    setLocation(null, arrivalAirports.value[0])
                }

                trackEvent('Set_ArrivalAirport')
            }

        }

        const checkLocationFormat = (input) => {
            const trimmedInput = input.trim();
            const cityIataPattern = /^[a-zA-Z\s]+ \([A-Z]{3}\)$/;

            // Check if input is already in "City (IATA)" format
            return cityIataPattern.test(trimmedInput)

        }

        const checkError = (input) => {

            if (input === 'depart') {
                return !searchKeywordDeparture.value || searchKeywordDeparture.value === searchKeywordArrival.value || searchKeywordDeparture.value === '' || !checkLocationFormat(searchKeywordDeparture.value)
            }

            if (input === 'arrival') {
                return !searchKeywordArrival.value || searchKeywordDeparture.value === searchKeywordArrival.value || searchKeywordArrival.value === '' || !checkLocationFormat(searchKeywordArrival.value)
            }

            if (input === 'departDate') {
                return !trip.value.departureDate
            }

            if (input === 'arrivalDate') {
                return !trip.value.arrivalDate
            }

        }

        const addTraveller = (isAdult, count) => {
            if (isAdult) {

                trip.value.travellers.adult += count
            } else {
                trip.value.travellers.children += count
            }
            trackEvent('Set_TravellerCount')
        }

        const pickers = reactive({});

        onMounted(async () => {

            await nextTick();

            const config = {
                disableMobile: "true",
                minDate: "today",
                altInput: true,
                altFormat: "m/d",
                dateFormat: "Y-m-d"
            }

            const params = extractFromUrl()

            pickers.departurePicker = flatpickr(departurePicker.value, {
                ...config,
                defaultDate: params.departureDate || '',
                onChange: function (selectedDates, dateStr, instance) {
                    trackEvent('Set_DepartureDate')
                    if (pickers.arrivalPicker) {
                        let nextDay = new Date(selectedDates[0]);
                        nextDay.setDate(selectedDates[0].getDate() + 1);
                        pickers.arrivalPicker.set('minDate', nextDay);
                        pickers.arrivalPicker.open()
                    }
                },
                onOpen: function (selectedDates, dateStr, instance) {
                    instance.setDate(new Date().getFullYear());
                },
            });

            pickers.arrivalPicker = flatpickr(arrivalPicker.value, {
                ...config,
                defaultDate: params.arrivalDate || '',
                onChange: function (selectedDates, dateStr, instance) {
                    trackEvent('Set_ArrivalDate')
                    if (pickers.departurePicker) {
                        let beforeDay = new Date(selectedDates[0]);
                        beforeDay.setDate(selectedDates[0].getDate() - 1);
                        pickers.departurePicker.set('maxDate', beforeDay);
                        travellersTriggerBtn.value.click()
                    }
                },
            });

        });



        const appendToUrl = (params) => {
            const url = new URL(window.location.href);
            url.search = '';
            Object.entries(params).forEach(([key, value]) => {
                if (value !== undefined && value !== null) {
                    url.searchParams.append(key, value.toString());
                }
            });
            window.history.pushState({}, '', url);
        }


        const extractFromUrl = () => {
            const urlParams = new URLSearchParams(window.location.search)

            const params = {
                departure: urlParams.get('departure'),
                arrival: urlParams.get('arrival'),
                departureDate: urlParams.get('departureDate'),
                arrivalDate: urlParams.get('arrivalDate'),
                adults: urlParams.get('adults'),
                children: urlParams.get('children'),
                sessionId: urlParams.get('sessionId'),
                step: urlParams.get('step'),
            }

            if (params.departure) {
                searchKeywordDeparture.value = params.departure
            }
            if (params.arrival) {
                searchKeywordArrival.value = params.arrival
            }
            if (params.departureDate) {
                trip.value.departureDate = params.departureDate
            }
            if (params.arrivalDate) {
                trip.value.arrivalDate = params.arrivalDate
            }
            if (params.adults) {
                trip.value.travellers.adult = parseInt(params.adults)
            }
            if (params.children) {
                trip.value.travellers.children = parseInt(params.children)
            }
            if (params.departureDate && !params.arrivalDate) {
                trip.value.tripType = 'one-way'
            } else if (params.departureDate && params.arrivalDate) {
                trip.value.tripType = 'round'
            }

            if (trip.value.tripType === 'one-way') {
                setTripType(tripTypes[1])
            }

            return params
        }

        const debounce = (func, delay) => {
            let timeoutId;
            return (...args) => {
                clearTimeout(timeoutId);
                timeoutId = setTimeout(() => {
                    func(...args);
                }, delay);
            };
        };


        const extractKeywords = (input) => {
            if (!input) return { iata: null }
            const regex = /^([\w\s]+)\s*\(?([\w]*)\)?$/;
            const match = input.match(regex);

            if (match) {
                return {
                    city: match[1].trim(),
                    iata: match[2].trim()
                };
            }

            return { city: null, iata: null };
        }

        const searchAirport = async (keyword, departing) => {
            if (!keyword) {
                return;
            }
            const res = localSearchAirport(keyword, airports.value, 0, departing)

            if (departing) {
                departureAirports.value = res
            } else {
                arrivalAirports.value = res
            }

        };


        const debouncedSearchAirportDeparture = debounce((keyword) => searchAirport(keyword, 1), 200);
        const debouncedSearchAirportArrival = debounce((keyword) => searchAirport(keyword, 0), 200);

        watch(searchKeywordDeparture, debouncedSearchAirportDeparture);
        watch(searchKeywordArrival, debouncedSearchAirportArrival);

        const selectText = (event, isDeparting) => {
            event.target.select()

            toggleLocationDropdown(isDeparting, true)

        }

        const scrollToTop = () => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }

        const filteredHistoryDeparture = computed(() => {

            if (searchKeywordDeparture.value) {
                return localSearchAirport(searchKeywordDeparture.value, historyDeparture.value, 1, 1)
            } else {
                return historyDeparture.value || []
            }
        })

        const filteredHistoryArrival = computed(() => {
            if (searchKeywordArrival.value) {
                return localSearchAirport(searchKeywordArrival.value, historyArrival.value, 1, 0);
            } else {
                return historyArrival.value || [];
            }
        });


        const resetScroll = (isDeparting) => {

            if (isDeparting) {
                showDepartureDropdown.value = true
            } else {
                showArrivalDropdown.value = true
            }

            if (isDeparting) {
                if (departureDropdown.value) {
                    departureDropdown.value.scrollTop = 0;
                }
            } else {

                if (arrivalDropdown.value) {
                    arrivalDropdown.value.scrollTop = 0;
                }
            }

        }

        const departureForm = ref()
        const arrivalForm = ref()

        const showDepartureDropdown = ref()
        const showArrivalDropdown = ref()

        const toggleLocationDropdown = (isDeparting, val) => {

            if (isDeparting) {
                showDepartureDropdown.value = val
            } else {
                showArrivalDropdown.value = val
            }

        }

        onClickOutside(departureForm, event => {
            toggleLocationDropdown(1, false)
        })

        onClickOutside(arrivalForm, event => {
            toggleLocationDropdown(0, false)
        })

        return {
            trip,
            languageData,
            selectArrival,
            travellersTriggerBtn,
            departureForm,
            departureDropdown,
            arrivalForm,
            arrivalDropdown,
            showDepartureDropdown,
            showArrivalDropdown,
            toggleLocationDropdown,


            // computed
            isRound,

            // method
            setTripType,
            tripTypes,
            addExtraPadding,

            departurePicker,
            arrivalPicker,
            searchKeywordDeparture,
            searchKeywordArrival,
            departureAirports,
            arrivalAirports,
            historyDeparture,
            historyArrival,
            tripOption,
            tripTypes,

            addTraveller,
            selectText,
            selectFirst,
            switchLocation,
            setLocation,
            searchAirport,
            checkError,
            createSession,
            scrollToTop,
            addExtraPadding,
            extractKeywords,
            filteredHistoryDeparture,
            filteredHistoryArrival,
            moveToDropdown,
            resetScroll
        };
    }
};
