// stores/user-session-store.js
import { defineStore } from 'pinia'

export const useUserSessionStore = defineStore('userSession', {
    state: () => ({
        // auth: true,
        // authToken: '123',
        auth: false,
        authToken: null,
    }),

    actions: {
        store(token) {
            this.authToken = token
            this.auth = !!token // Set `auth` to true if the token exists
            localStorage.setItem('authToken', token)
        },

        load() {
            const token = localStorage.getItem('authToken')
            if (token) {
                this.authToken = token
                this.auth = true
            }
        },

        clear() {
            this.authToken = null
            this.auth = false
            localStorage.removeItem('authToken') // Clear token from storage
        },

        getAuthToken() {
            return this.authToken
        },

        setAuthStatus(status) {
            this.auth = status
        }

    },
})
