const environment = process.env

export const app = {
    env: environment.VITE_ENV,
    debug: environment.VITE_APP_DEBUG,
    hotReload: environment.VITE_APP_HOT_RELOAD,
    tokenExToken: environment.VITE_PAYMENTS_TOKENEX_TOKEN,
    mode: environment.MODE,
    showErrorModal: environment.VITE_APP_ERRORS_MODAL,
    gaId: environment.VITE_GA_MEASUREMENT_ID,
    authUrl: environment.VITE_APP_AUTH_URL,
    gaConversionId: environment.VITE_GA_CONVERSION_ID,
    gaConversionLabel: environment.VITE_GA_CONVERSION_LABEL,
    hotjarId: environment.VITE_HOTJAR_ID,
}

export const api = {
    base: environment.VITE_APP_API_BASE,
    mode: environment.VITE_APP_API_MODE,
}

export const web = {
    base: environment.VITE_APP_WEB_BASE,
}

export const ext = {
    chrome: { url: environment.VITE_APP_EXT_CHROME_URL },
    edge: { url: environment.VITE_APP_EXT_EDGE_URL },
}

export default {
    api,
    app,
    ext,
    web,
}
