import UsersAPI from "../data/api/users-api-store.js"; // Assuming there's an API file for user-related endpoints

export default class UserService {

    static async show() {
        try {
            return await UsersAPI.show()
        } catch (error) {
            console.error('UserService.show - Error: ', error)
            throw error
        }
    }

    static async update(request) {
        try {
            return await UsersAPI.update(request)
        } catch (error) {
            console.error('UserService.update - Error: ', error)
            throw error
        }
    }

    static async delete() {
        try {
            return await UsersAPI.delete();
        } catch (error) {
            console.error('UserService.delete - Error: ', error);
            throw error;
        }
    }

}
