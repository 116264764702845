import { ref, provide, inject, computed, getCurrentInstance } from 'vue'
import useBrowser from '../../../utilities/use-browser.js';
import FloatingVue from 'floating-vue'
import i18nService from '../../../services/i18n-service.js';
import { useUserSessionStore } from '../../../stores/user-session-store.js';

export default {
    setup(props, { attrs, emit }) {

        const userSessionStore = useUserSessionStore()

        const instance = getCurrentInstance();
        const app = instance.appContext.app;

        app.use(FloatingVue)

        const languageData = inject('$languageData');

        const languageDataCommon = computed(() => languageData.value.common);
        const languageDataHow = computed(() => languageData.value.how);


        const language = inject('$language');
        const extensionLabel = inject('$extensionLabel')


        const {
            useComputedExtensionLabel,
            isMobile,
        } = useBrowser()

        const computedExtensionLabel = useComputedExtensionLabel()

        const menuOpen = ref()

        const menu = computed(() => [
            {
                copy: languageDataCommon.value.homeFooterLink,
                name: 'home',
                hash: ''
            },
            {
                copy: languageDataCommon.value.howItWorksNavButton,
                name: 'how-it-works',
                hash: '#how-it-works'
            },
            {
                copy: languageDataCommon.value.pointsNavButton,
                name: 'points'
            },
            // {
            //     copy: languageDataCommon.value.browserExtension,
            //     href: languageDataHow.value.heroDownloadLink,
            //     disable: isMobile,
            // },
            {
                copy: languageDataCommon.value.browserExtensionNavButton,
                name: 'add-to-browser'
            },
            {
                copy: languageDataCommon.value.faq,
                name: 'faq-general'
            }
        ])


        const subMenu = computed(() => [
            {
                copy: languageDataCommon.value.languages[language.value] || languageDataCommon.value.languages.en,
                icon: 'icon-globe-outlined',
                type: 'lang'
            },
            {
                copy: languageDataCommon.value.account,
                icon: 'icon-account-filled',
                type: 'account',
                disable: !userSessionStore.auth
            },
            {
                copy: languageDataCommon.value.logout,
                icon: 'icon-exit-outlined',
                type: 'logout',
                disable: !userSessionStore.auth
            },
            {
                copy: languageDataCommon.value.login,
                icon: 'icon-account-filled',
                type: 'login',
                disable: userSessionStore.auth
            }
        ])

        const goToHash = (item) => {
            if (item.hash) {
                location.hash = item.hash
                menuOpen.value = !menuOpen.value
            }
            if (item.href) {
                window.location.href = item.href;
            }
        }

        const handleClick = async (item) => {

            if (item.href) {
                window.location.href = item.href;
            }

            if (item.type === 'lang') {
                toggleLangDialog()
            }

            if (item.type === 'account') {
                emit('open-user-manage-modal');

            }

            if (item.type === 'login') {
                emit('log-in');
            }

            if (item.type === 'logout') {
                emit('log-out');
            }

            menuOpen.value = !menuOpen.value;

        }

        const toggleMenu = () => {
            menuOpen.value = !menuOpen.value
        }

        const showLangDialog = ref()

        const toggleLangDialog = () => {
            showLangDialog.value = !showLangDialog.value
        }

        const switchLang = async (lang) => {
            language.value = lang
            languageData.value = await i18nService.index(lang);
            localStorage.setItem('language', lang);
            toggleLangDialog()
            window.location.reload()
        }

        return {
            menu,
            menuOpen,
            subMenu,
            showLangDialog,
            goToHash,
            handleClick,
            toggleMenu,
            languageDataCommon,
            switchLang,
            language,
            userSessionStore
        }

    },
    inheritAttrs: false,

};
