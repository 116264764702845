import { defineStore } from 'pinia';

export const useSearchHistoryStore = defineStore('searchHistory', {
  state: () => ({
    departureHistory: JSON.parse(localStorage.getItem('departureHistory') || '[]'),
    arrivalHistory: JSON.parse(localStorage.getItem('arrivalHistory') || '[]')
  }),
  actions: {
    // Add search term to the relevant history
    addSearchTerm(term, isDeparting) {
      let iata = this.extractKeywords(term).iata

      if (isDeparting && iata) {
        this._addToHistory(iata, 'departureHistory')
      } else {
        this._addToHistory(iata, 'arrivalHistory')
      }
    },

    // Internal function to update and persist history
    _addToHistory(term, historyType) {
      if (!this[historyType].includes(term)) {
        this[historyType].unshift(term); // Add to the beginning
        if (this[historyType].length > 10) {
          this[historyType].pop(); // Limit to 10 items
        }
        localStorage.setItem(historyType, JSON.stringify(this[historyType]));
      }
    },

    // Clear the relevant history
    clearHistory(type) {
      if (type === 'departure') {
        this.departureHistory = [];
        localStorage.removeItem('departureHistory');
      } else if (type === 'arrival') {
        this.arrivalHistory = [];
        localStorage.removeItem('arrivalHistory');
      }
    },

    extractKeywords(input) {
      if (!input) return { iata: null }
      const regex = /^([\w\s]+)\s*\(?([\w]*)\)?$/;
      const match = input.match(regex);

      if (match) {
        return {
          city: match[1].trim(),
          iata: match[2].trim()
        };
      }

      return { city: null, iata: null };
    }


  },
  getters: {
    getDepartureHistory: (state) => state.departureHistory,
    getArrivalHistory: (state) => state.arrivalHistory
  }
});
