import Footer from '../../molecules/pg-footer/pg-footer.vue';
import useBrowser from '../../../utilities/use-browser.js'
import { onMounted, ref, getCurrentInstance, provide, inject, watch, computed } from 'vue'
import environment from '../../../../.configs/app/environment.js';
import { useRoute, useRouter } from 'vue-router';
import 'floating-vue/dist/style.css'
import { Dropdown as VDropdown, options } from 'floating-vue';
import UserService from '../../../services/users-service.js';
import UserSessionService from '../../../services/user-session-service.js';
import { useUserSessionStore } from '../../../stores/user-session-store.js';
import { useUserStore } from '../../../stores/user-store.js';
import { useAnalytics } from '../../../utilities/use-analytics.js'
import { useIdle } from '@vueuse/core';
import PointsService from '../../../services/points-service.js';

export default {
    components: { Footer },

    setup() {
        const instance = getCurrentInstance();
        const app = instance.appContext.app;
        app.component('VDropdown', VDropdown);

        const { trackEvent } = useAnalytics()
        const route = useRoute()
        const showDialog = ref()

        const localUser = ref({})

        const showLoginModal = ref()

        const updateShowDialog = (val) => {
            showDialog.value = val
        }

        provide('showDialog', { showDialog, updateShowDialog })

        const extensionLabel = inject('$extensionLabel')

        const {
            isTouch,
        } = useBrowser()

        const href = (url, inNewTab, addToChromeBtn) => {
            if (!isTouch) {
                if (inNewTab) {
                    window.open(url, '_blank');
                } else {
                    window.location.href = url;
                }

            } else {
                showDialog.value = true
            }

            if (inNewTab) {
                window.open(url, '_blank');
            } else {
                window.location.href = url;
            }
        }

        const goToHash = (item) => {

            if (route.path === '/checkout') {

                if (item.name === 'faq-general') {
                    trackEvent('faq_click')
                }

                if (item.name === 'how-it-works') {
                    trackEvent('how_it_works_click')
                }

                if (item.name === 'home') {
                    trackEvent('home_click')
                }

            }

            if (item.hash) {
                location.hash = item.hash
            }
        }

        const languageData = inject('$languageData').value.common

        const navigation = ref({
            logo: {
                src: '/assets/images/jauntr-logo.svg',
                alt: languageData.jauntrLogoAlt,
                name: 'home'
            },
            menu: {
                howItWorks: {
                    copy: languageData.howItWorksNavButton,
                    name: 'how-it-works',
                },
                // about: {
                //     copy: languageData.aboutUsNavButton,
                //     name: 'about-us'
                // },
                points: {
                    copy: languageData.pointsNavButton,
                    name: 'points'
                },
                addToBrowser: {
                    copy: languageData.browserExtensionNavButton,
                    name: 'add-to-browser'
                },
                support: {
                    copy: languageData.faq,
                    name: 'faq-general'
                },
            },
            action: {
                copy: extensionLabel,
                href: environment.ext.url
            }
        })

        const footerSections = [
            {
                title: "Jauntr",
                separator: true,
                links: [
                    { text: languageData.homeFooterLink, name: "home" },
                    { text: languageData.howItWorksNavButton, name: 'how-it-works' },
                    { text: languageData.pointsNavButton, name: 'points' },
                    // { text: languageData.aboutUsNavButton, name: "about-us" },
                    // { text: languageData.supportNavButton, name: "support" },
                ],
            },
            {
                title: languageData.faq,
                separator: true,
                links: [
                    { text: languageData.faqGeneralFooterLink, name: "faq-general" },
                    { text: languageData.faqBookingsFooterLink, name: "faq-bookings" },
                    { text: languageData.faqFooterHeader, name: "faq-pricing" },
                    //     { text: languageData.faqOffersFooterLink, name: "faq-offers" },
                    //     { text: languageData.faqPointsFooterLink, name: "faq-points" },
                ],
            },
            {
                title: `© ${new Date().getFullYear()}`,
                separator: true,
                links: [
                    { text: languageData.termsFooterLink, name: "terms-and-conditions" },
                    { text: languageData.privacyPolicyFooterLink, name: "privacy-policy" },
                    { text: languageData.contactFooterLink, name: "faq-general" },
                ],
            },
            // {
            //     title: "Social Links",
            //     separator: true,
            //     links: [
            //         { text: "Facebook", },
            //         { text: "Twitter", },
            //         { text: "Instagram", },
            //     ],
            // },
        ]

        const toggleLoginModal = (hide) => {
            if (!userSessionStore.auth) {
                showLoginModal.value = true

                if (!hide) {
                    trackEvent('ClickCTA_Login')
                }

                if (hide) {
                    hide()
                    showLoginModal.value = false
                }

            } else {

            }
        }

        const login = async () => {
            let data;
            try {
                data = await UserSessionService.login()
            } catch (e) {
                return
            }

            window.location.href = data.redirect_url


        }

        const logout = async () => {

            await UserSessionService.logout()
            userSessionStore.clear()

        }

        const showUserManageModal = ref()

        const userStore = useUserStore()

        const toggleUserManageModal = async (hide) => {

            if (hide) {
                hide()
                showUserManageModal.value = false
                userManageStep.value = 0

            } else {

                showUserManageModal.value = true

                const userData = await UserService.show()

                if (userData) {
                    userStore.setUserData(userData)
                    localUser.value = userStore.getUserDate()
                }

            }
        }

        const handleClick = (item, hide) => {

            if (item.value === 'account') {
                hide()
                toggleUserManageModal()
            }

            if (item.value === 'logout') {
                logout()
            }
        }

        const userManageStep = ref(0)
        //  0 - start
        //  1 - edit name
        //  2 - delete account 
        //  3 - account deleted

        const resetKey = ref(0)
        const updateEditNameStep = (val) => {
            if (userManageStep.value === 1) {
                localUser.value.firstName = '';
                localUser.value.lastName = '';
                resetKey.value += 1;
            }

            userManageStep.value = val
        }

        const getModalTitle = computed(() => {
            if (userManageStep.value == 0 || userManageStep.value == 1) {
                return languageData.manageAccount
            }
            if (userManageStep.value == 2) {
                return languageData.deleteJauntrAccount
            }
            if (userManageStep.value == 3) {
                return languageData.accountDeleted
            }

        })

        const deletingAccount = ref()

        const deleteAccount = () => {
            deletingAccount.value = true
        }

        const getCookie = (name) => {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) return parts.pop().split(';').shift();
            return null;
        }

        const userSessionStore = useUserSessionStore()

        const updateUserName = async () => {

            await UserService.update({
                first_name: localUser.value.firstName,
                last_name: localUser.value.lastName,
            })
        }

        const balance = ref({})
        const displayBalance = async () => {
            try {
              const response = await PointsService.display()
              balance.value = response.points
              console.log(balance.value)
            } catch (error) {
              return
            }
        }
        const rewards = computed(() => {
            const calculatedRewards = balance.value / 100
            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
            }).format(calculatedRewards)
        })

        const initials = computed(() => {
            if (userStore.firstName && userStore.lastName) {
                return `${userStore.firstName.charAt(0)}${userStore.lastName.charAt(0)}`;
            } else {
                return "null";
            }
        });

        onMounted(async () => {
            let res = await UserService.show()
            if (res && res.id) {
                userSessionStore.setAuthStatus(true)
                displayBalance()
            } else {
                userSessionStore.setAuthStatus(false)
            }
        })

        const { idle, reset } = useIdle(20000) //  20s

        // watch(idle, (idleValue) => {
        //     if (idleValue) {
        //         trackEvent('user_inactive')
        //         reset() 
        //     }
        // })

        window.addEventListener('visibilitychange', () => {
            if (document.visibilityState === 'visible') {
                trackEvent('returned_to_tab')
            }
            if (document.visibilityState === 'hidden') {
                trackEvent('left_tab')
            }
        })

        const dropdownOpen = ref(false)
        const openDropdown = () => {
            dropdownOpen.value = true;
            document.body.style.overflow = 'hidden';
        }
        const closeDropdown = () => {
            dropdownOpen.value = false;
            if (showLoginModal.value) {
                showLoginModal.value = false;
            }
            if (showUserManageModal.value) {
                showUserManageModal.value = false;
                userManageStep.value = 0
            }
            document.body.style.overflow = '';
        }

        const router = useRouter();

        const navigateToPoints = (hide) => {
            router.push('/points');
            toggleUserManageModal(hide)
        };

        return {
            route,
            href,
            balance,
            displayBalance,
            rewards,
            initials,
            showDialog,
            goToHash,
            footerSections,
            navigation,
            languageData,
            showLoginModal,
            toggleLoginModal,
            login,
            dropdownOpen,
            openDropdown,
            closeDropdown,
            showUserManageModal,
            toggleUserManageModal,
            handleClick,
            userManageStep,
            resetKey,
            updateEditNameStep,
            getModalTitle,
            deletingAccount,
            deleteAccount,
            userSessionStore,
            userStore,
            localUser,
            updateUserName,
            logout,
            router,
            navigateToPoints
        }

    },
};