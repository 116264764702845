import { defineStore } from 'pinia'

export const useUserStore = defineStore('user', {
    state: () => ({
        firstName: null,
        lastName: null,
        email: null,
    }),

    getters: {
        fullName: (state) => `${state.firstName} ${state.lastName}`,  // Computed full name

        hasName: (state) => {
            return state.firstName.trim() !== '' || state.lastName.trim() !== ''
        },
    },

    actions: {
        setUserData({ first_name, last_name, email }) {
            this.firstName = first_name
            this.lastName = last_name
            this.email = email
        },

        getUserDate() {
            return {
                firstName: this.firstName,
                lastName: this.lastName,
                email: this.email,
            }
        },

        clearUserData() {
            this.firstName = ''
            this.lastName = ''
            this.email = ''
        },
    },
})
