<template>
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="64" height="64" rx="32" fill="#FDF2F2"/>
        <text 
            x="50%" 
            y="50%" 
            text-anchor="middle" 
            dominant-baseline="middle"
            font-size="30" 
            font-weight="bold"
            fill="#E14E4E">{{ initials }}</text>
    </svg>
</template>

<style lang="scss" src="./atm-icon.scss"></style>
<script src="./atm-icon.js"/>