// tracking.js
import { useRouter } from 'vue-router'
import { useGtag } from 'vue-gtag-next'
import environment from '../../.configs/app/environment'

export function useAnalytics() {
    const { event } = useGtag()
    const router = useRouter()

    function trackEvent(eventName, eventParams = {}, pageContext = {}) {
        try {
            let currentPath = router.currentRoute.value.path.slice(1).replaceAll(/\//g, '_')
            if (currentPath === '') {
                currentPath = 'home'
            }
            if (currentPath === 'search' && pageContext.step) {
                if (pageContext.step <= 1) {
                    currentPath = 'search_departure'
                }
                if (pageContext.step === 2) {
                    currentPath = 'search_arrival'
                }
            }

            event(`${currentPath}_${eventName}`, {
                ...eventParams,
                send_to: environment.app.gaId,
                page_path: currentPath
            })
        } catch (error) {
            console.error('Error tracking event', error)
        }
    }

    return { trackEvent }
}

export function useAds() {
    const { event } = useGtag()
    async function trackConversions(value, orderId) {
        try {
            event('conversion', {
                // When using useGtag's events, it does not track conversions correctly
                send_to: `${environment.app.gaConversionId}/${environment.app.gaConversionLabel}`,
                value: value, // Price of trip
                currency: 'CAD',
                transaction_id: await hash(orderId),
                // TODO: temp hash, need to actually anonymize according to GDPR
            });
        } catch (error) {
            console.error('Error tracking conversion', error)
        }
    }

    return { trackConversions }
}

async function hash(string) {
    const encoder = new TextEncoder()
    const data = encoder.encode(string)

    const hashBuffer = await crypto.subtle.digest('SHA-256', data)

    const hashArray = Array.from(new Uint8Array(hashBuffer))
    const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('')

    return hashHex
}
